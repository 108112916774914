<template>
  <div class="page__device-account">
    <y-form
      inline
      label-width="0"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="submitter"
      @submit="add"
    ></y-form>
    <y-table ref="tableRef" :columns="columns" :fetch="fetchFn"></y-table>
  </div>
</template>

<script>
export default {
  name: 'DeviceAccount',
  data() {
    return {
      model: {
        email: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '',
            prop: 'email'
          }
        }
      ],
      rules: {
        email: [
          { required: true, message: '邮箱账号不能为空', trigger: 'blur' }
        ]
      },
      submitter: {
        submitText: '添加测试账号',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      },
      columns: [
        {
          label: '用户名',
          prop: 'email'
        },
        {
          label: '操作',
          render: (h, record) => {
            return (
              <el-button
                vOn:click={() => this.deleteAction(record)}
                type="text"
              >
                删除
              </el-button>
            )
          }
        }
      ]
    }
  },
  methods: {
    fetchFn(params) {
      return this.$serve
        .userList({
          params
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return res
        })
    },
    deleteAction(record) {
      this.$confirm('确认删除当前测试账号吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(record)
        })
        .catch(() => {})
    },
    async delete(record) {
      const res = await this.$serve.userDelete({
        aimid: record.userId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message({
        message: '删除测试账号成功',
        type: 'success'
      })
      this.$refs.tableRef.fetchData()
    },
    async add() {
      const res = await this.$serve.userAdd({
        data: this.model
      })

      if (res === this.$serve.FAIL) {
        return
      }

      this.model.email = ''

      this.$message({
        message: '添加测试账号成功',
        type: 'success'
      })

      this.$refs.tableRef.fetchData({
        currentPage: 1
      })
    }
  }
}
</script>
