<template>
  <div class="page__device-list">
    <y-list-page
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <el-button @click="exportData" type="primary">导出数据</el-button>
      </template>
    </y-list-page>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DeviceList',
  data() {
    return {
      model: {
        deviceType: '',
        searchLabel: 'serialNum',
        searchValue: '',
        activeTime: []
      },
      fields: [
        {
          type: 'select',
          formItemProps: {
            label: '型号',
            prop: 'deviceType'
          },
          attributes: {
            placeholder: '请选择型号',
            style: 'width: 100%',
            options: [
              {
                label: '全部',
                value: ''
              },
              ...this.$enums.deviceTypeList
            ]
          }
        },
        {
          formItemProps: {
            label: ''
          },
          render(h, prop, field, model) {
            return (
              <el-input
                v-model={model['searchValue']}
                placeholder="请输入内容"
                class="input-with-select"
              >
                <el-select
                  v-model={model['searchLabel']}
                  slot="prepend"
                  placeholder="请选择"
                >
                  <el-option label="序列号" value="serialNum"></el-option>
                  <el-option
                    label="软件版本"
                    value="softWareVersion"
                  ></el-option>
                  <el-option
                    label="固件版本"
                    value="firmWareVersion"
                  ></el-option>
                  <el-option label="邮箱" value="email"></el-option>
                </el-select>
              </el-input>
            )
          }
        },
        {
          type: 'date-range',
          formItemProps: {
            label: '激活时间',
            prop: 'activeTime'
          }
        }
      ],
      columns: [
        {
          label: '序列号',
          prop: 'serialNum'
        },
        {
          label: '用户名（email）',
          prop: 'email'
        },
        {
          label: '型号',
          prop: 'deviceType',
          render(h, v) {
            return v == undefined ? '-' : this.$enumsMap.deviceTypeMap[v]
          }
        },
        {
          label: '软件版本',
          render(h, record) {
            return (
              (record.softWareVersion || '-') +
              '(' +
              (record.softwareVersionCode || '-') +
              ')'
            )
          }
        },
        {
          label: '固件版本',
          render(h, record) {
            return (
              (record.firmWareVersion || '-') +
              '(' +
              (record.firmwareVersionCode || '-') +
              ')'
            )
          }
        },
        {
          label: '激活时间',
          prop: 'activationTime',
          render(h, v) {
            return v == undefined ? '-' : v
          }
        }
      ]
    }
  },
  methods: {
    getQuery({ activeTime, searchLabel, searchValue, ...params }) {
      if (activeTime?.[0]) {
        params.startTime = moment(activeTime[0]).format('YYYY-MM-DD')
        params.endTime = moment(activeTime[1]).format('YYYY-MM-DD')
      }
      return {
        ...params,
        [searchLabel]: searchValue
      }
    },
    fetchFn(data) {
      return this.$serve
        .deviceList({
          params: this.getQuery(data)
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return res
        })
    },
    exportData() {
      this.$download({
        fn: 'deviceExport',
        params: this.getQuery(this.model),
        filename: '设备列表.xls'
      })
    }
  }
}
</script>

<style lang="scss">
.page__device-list {
  .input-with-select {
    .el-input-group__prepend {
      background-color: #fff;
    }
    .el-select .el-input {
      width: 100px;
    }
  }
}
</style>
